import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Carousel from "../components/carousel-v2";

//* building a functional component
const WorkPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query WorkQuery {
      site {
        siteMetadata {
          siteTitle
          author
          social {
            twitter
          }
        }
      }
      website: allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "website" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              category
              title
              description
              url {
                childImageSharp {
                  gatsbyImageData(
                    width: 280
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            id
          }
        }
      }
      youtube: allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "youtube" } } }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              category
              title
              description
              url {
                childImageSharp {
                  gatsbyImageData(
                    width: 280
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            id
          }
        }
      }
    }
  `);
  return (
    <Layout location={location} title="Work">
      <Seo
        title="Work Page"
        description="My work as a web developer and a video creator. E-commerce websites using GatsbyJS, WordPress, and Shopify. Videos are live on YouTube. "
      />
      <main>
        <p>
          I build e-commerce websites using GatsbyJS, WordPress, and Shopify.
        </p>
        <p>
          I spend most of my time building websites, but I also create web apps,
          working with Google Analytics and Google Tag Manager.
        </p>
        <p>
          I also make videos on YouTube to help others learn. Find more of my
          YouTube episodes at my channel,{" "}
          <a className="purple" href="https://www.youtube.com/user/shimdoggy">
            Shimmin Web Dev
          </a>
        </p>
        <p>
          In 2018, I created a web development company called Hoboken Web
          Solutions. It was created to help local businesses with their website
          needs. We build and design websites, specializing in e-commerce. We
          also help with marketing and analytics of each website, so the
          customer knows how to spend money online.
        </p>

        <p>
          <br></br>
        </p>
      </main>
      <Carousel key="1" title="Websites" data={data.website.edges} />

      <Carousel key="1" title="YouTube" data={data.youtube.edges} />
    </Layout>
  );
};
export default WorkPage;
